// import Default from 'gatsby-theme-carbon/src/templates/Default';
import React from "react";
import { SetNewPasswordForm } from "../components";
export default function PasswordSetPage({ pageContext, location }) {
  pageContext = { ...pageContext, frontmatter: { title: "Reset Password" } };

  return (
    <>
      <SetNewPasswordForm />
    </>
  );
}
